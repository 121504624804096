<script lang="ts" setup>
import { useMutation, useQueryClient } from 'vue-query'
import type { AuctionItem, AuctionLayoutType } from '~/types/auction'
import { fileToBase64Image } from '~/utils/files'
import { useAuctionItems } from '~/composables/api/useAuctionItems'
import { useAlertStore } from '~/stores/alert'

const props = defineProps<{
  modelValue: boolean
  auctionItem?: AuctionItem
}>()

const emit = defineEmits<{
  (event: 'update:modelValue', value: boolean): void
}>()

const { create, update, uploadAuctionItemPhoto } = useAuctionItems()
const alertStore = useAlertStore()
const { captureException } = useSentry()
const queryClient = useQueryClient()

interface FileFieldValue { name: string; file: File }

const imageFile0 = ref<FileFieldValue[] | undefined>(undefined)
const imageFile1 = ref<FileFieldValue[] | undefined>(undefined)

const form = ref<Partial<AuctionItem>>({
  id: props.auctionItem?.id || undefined,
  name: props.auctionItem?.name || '',
  description: props.auctionItem?.description || '',
  images: [props.auctionItem?.images?.[0] || '', props.auctionItem?.images?.[1] || ''],
  imagesLayout: props.auctionItem?.imagesLayout || 'two-horizontal',
  priority: props.auctionItem?.priority || undefined,
  video: props.auctionItem?.video || undefined,
  aspectRatio: props.auctionItem?.aspectRatio || 1,
})

function resetForm() {
  form.value = {
    id: props.auctionItem?.id || undefined,
    name: props.auctionItem?.name || '',
    description: props.auctionItem?.description || '',
    images: [props.auctionItem?.images?.[0] || '', props.auctionItem?.images?.[1] || ''],
    imagesLayout: props.auctionItem?.imagesLayout || 'two-horizontal',
    priority: props.auctionItem?.priority || undefined,
    video: props.auctionItem?.video || undefined,
    aspectRatio: props.auctionItem?.aspectRatio || 1,
  }
}

function closeModal() {
  emit('update:modelValue', false)
  resetForm()
}

const { mutateAsync: onSubmit, isLoading } = useMutation({
  mutationFn: async () => {
    const { id, name, description, imagesLayout, aspectRatio, priority, video } = form.value

    try {
      const response = id
        ? await update(id, {
          name,
          description,
          imagesLayout,
          aspectRatio,
          priority,
          video,
        })
        : await create({
          name: name || '',
          description: description || '',
          imagesLayout: imagesLayout || 'two-horizontal',
          aspectRatio: aspectRatio || 1,
          images: ['', ''],
          priority,
          video,
        })

      // Upload images and get urls
      const newId = response.id as string
      const files = [imageFile0.value?.[0]?.file, imageFile1.value?.[0]?.file]
      const uploadedImages: [string, string] = await Promise.all(files.map(file => new Promise((resolve) => {
        if (!file) {
          resolve('')
          return
        }

        uploadAuctionItemPhoto(newId, file).then((imageUrl) => {
          resolve(imageUrl)
        }).catch((e) => {
          captureException(e)
          console.error(e)
          alertStore.setAlert(`Couldn't upload ${file.name}`, 'error')
          resolve('')
        })
      })))

      // Save new images
      await update(newId, {
        images: [uploadedImages[0] || props.auctionItem?.images?.[0] || '', uploadedImages[1] || props.auctionItem?.images?.[1] || ''],
      })

      await queryClient.invalidateQueries(['auctionItems'])
      alertStore.setAlert(`Successfully ${id ? 'updated' : 'created'} auction item`, 'success')
      closeModal()
    }
    catch (e) {
      captureException(e)
      alertStore.setAlert(`Couldn't ${id ? 'update' : 'create'} auction item`, 'error')
      console.error(e)
    }
  },
})

const layoutOptions: { label: string; value: AuctionLayoutType }[] = [
  {
    label: 'Horizontal | Horizontal (two rows)',
    value: 'two-horizontal',
  },
  {
    label: 'Horizontal | Vertical',
    value: 'horizontal-vertical',
  },
  {
    label: 'Vertical | Horizontal',
    value: 'vertical-horizontal',
  }, {
    label: 'Vertical | Vertical',
    value: 'two-vertical',
  },
  {
    label: 'Single Image',
    value: 'single',
  },
]

const modalTitle = computed(() => `${props.auctionItem ? 'Edit' : 'Create'} auction item`)

async function handleFileRefChange(fileValue: FileFieldValue[] | undefined, index: number) {
  console.log('Change', index)

  const file = fileValue?.[0]?.file

  if (!file) {
    if (form.value.images)
      form.value.images[index] = props.auctionItem.images[index]

    return
  }

  const image = await fileToBase64Image(file) || ''

  console.log(file, image)

  const images = [...form.value.images]
  images[index] = image

  form.value = {
    ...form.value,
    images,
  }
}

watch(imageFile0, v => handleFileRefChange(v, 0), { deep: true })
watch(imageFile1, v => handleFileRefChange(v, 1), { deep: true })
</script>

<template>
  <CommonPopup :model-value="modelValue" :title="modalTitle" @update:model-value="$emit('update:modelValue', $event)">
    <FormKit type="form" :actions="false" @submit="onSubmit">
      <FormKit v-model="form.name" type="text" label="Name" />
      <FormKit type="textarea" label="Description">
        <template #inner>
          <BorrachoEditor v-model="form.description" placeholder="Auction item description..." />
        </template>
      </FormKit>

      <div class="grid grid-cols-2 gap-x-3">
        <FormKit v-model="form.imagesLayout" type="select" :options="layoutOptions" label="Images Layout" />
        <FormKit v-model="form.aspectRatio" type="number" label="Aspect Ratio" help="0 to set as auto" step="0.1" />
        <FormKit v-model="imageFile0" type="file" label="Image 1" />
        <FormKit v-if="form.imagesLayout !== 'single'" v-model="imageFile1" type="file" label="Image 2" />
        <FormKit v-model="form.priority" type="number" label="Priority" />
        <FormKit v-model="form.video" type="text" label="Youtube Embed" />
      </div>

      <div class="p-5 border border-t-border rounded-xl">
        <AuctionItemCard :item="form" class="max-w-md mx-auto" />
      </div>

      <div class="grid lg:grid-cols-2 gap-4 mt-6">
        <CommonButton pre-icon="ri:close-line" :disabled="isLoading" type="button" theme="neutral-light" block @click="closeModal">
          Cancel
        </CommonButton>
        <CommonButton :loading="isLoading" type="submit" theme="green" post-icon="ri:check-line" block>
          Save
        </CommonButton>
      </div>
    </FormKit>
  </CommonPopup>
</template>
