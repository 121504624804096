<script lang="ts" setup>
import { useMutation, useQueryClient } from 'vue-query'
import type { AuctionItem, AuctionLayoutType } from '~/types/auction'
import Button from '~/components/Common/Button.vue'
import { useAuctionItems } from '~/composables/api/useAuctionItems'
import { useAlertStore } from '~/stores/alert'

const props = defineProps<{
  item: AuctionItem
  editable?: boolean
}>()

const { deleteItem } = useAuctionItems()
const alertStore = useAlertStore()
const client = useQueryClient()
const { captureException } = useSentry()

const classesByLayout: Record<AuctionLayoutType, string> = {
  'single': '',
  'two-horizontal': 'flex-col [&>div]:flex-1 [&>div]:w-full',
  'horizontal-vertical': '[&>div:first-child]:w-2/3 [&>div:last-child]:w-1/3',
  'vertical-horizontal': '[&>div:first-child]:w-1/3 [&>div:last-child]:w-2/3',
  'two-vertical': 'flex-row [&>div]:flex-1',
}

const showEditModal = ref(false)
const showWinnerModal = ref(false)

const layoutClass = computed(() => {
  const layout = props.item.imagesLayout as AuctionLayoutType

  if (!layout)
    return false

  return classesByLayout[layout]
})

const { mutateAsync: deleteAuctionItem, isLoading } = useMutation({
  mutationFn: async () => {
    if (!props.item.id) {
      alert('This item has no id')
      return
    }

    await deleteItem(props.item.id)
    await client.invalidateQueries(['auctionItems'])
  },
  onSuccess: () => {
    alertStore.setAlert('Auction item deleted', 'success')
  },
  onError: (e) => {
    alertStore.setAlert('Unable to delete auction item', 'error')
    console.error(e)
    captureException(e)
  },
})

function confirmDelete() {
  if (confirm('Do you really want to delete this auction item?'))
    deleteAuctionItem(undefined)
}
</script>

<template>
  <div class="pt-5 break-inside-avoid-column">
    <div class="p-1 rounded-2xl theme-coral bg-t-bg text-t-text-light flex relative">
      <!-- Icons -->
      <div class="absolute w-8 lg:w-10 h-8 lg:h-10 bg-t-bg rounded-full top-1 mt-px left-1/2 -translate-x-1/2 -translate-y-1/2 flex items-center justify-center p-1" />
      <div class="absolute w-8 lg:w-10 h-8 lg:h-10 rounded-full top-1 mt-px left-1/2 -translate-x-1/2 -translate-y-1/2 flex items-center justify-center p-1 z-[1]">
        <div class="flex items-center bg-t-bg justify-center w-full h-full rounded-full border border-t-border">
          <Icon name="ri:auction-line" class="w-3 lg:w-4 h-3 lg:h-4 text-t-text-light" />
        </div>
      </div>

      <!-- Content -->
      <div class="px-4 lg:px-5 pb-4 lg:pb-5 pt-5 lg:pt-6 rounded-xl border border-t-border w-full relative">
        <!-- Name -->
        <h2 class="heading text-2xl lg:text-4xl text-t-text">
          {{ item.name || "No Name" }}
        </h2>

        <!-- Images -->
        <div v-if="item.images?.length" class="mt-3 lg:mt-5 w-full overflow-hidden relative rounded-xl" :style="{ paddingBottom: `${1 / (item?.aspectRatio ?? 1) * 100}%` }">
          <div class="absolute left-0 top-0 right-0 bottom-0 flex" :class="layoutClass">
            <template v-if="item.imagesLayout !== 'single'">
              <div v-for="image in item.images" :key="image" class="relative overflow-hidden">
                <img :src="image" class="fill-img">
              </div>
            </template>
            <template v-else>
              <div class="relative overflow-hidden w-full">
                <img :src="item.images[0]" class="fill-img">
              </div>
            </template>
          </div>
        </div>

        <!-- Description -->
        <div class="mt-3 lg:mt-5 text-sm lg:text-base html-description" v-html="decodeHTMLEntities(item.description) || 'No description'" />

        <!-- Actions -->
        <div v-if="editable" class="flex flex-wrap gap-3 mt-4">
          <Button type="button" title="Delete" pre-icon="ri:close-line" theme="neutral" :loading="isLoading" @click="confirmDelete" />
          <Button type="button" title="Edit" post-icon="mi:edit" theme="neutral-light" :disabled="isLoading" @click="showEditModal = true" />
          <Button v-if="!item.purchaseBid" type="button" title="winner" post-icon="mi:edit" theme="neutral-light" :disabled="isLoading" @click="showWinnerModal = true" />
          <Button :to="`/public/auction/${item.priority}`" title="See Public View" pre-icon="ri:eye-line" theme="neutral-light" />
          <AuctionItemEditModal v-model="showEditModal" :auction-item="item" />
          <AuctionWinnerModal v-model="showWinnerModal" :auction-item="item" />
        </div>
        <div v-if="item.purchaseBid" class="flex flex-wrap gap-3 mt-4">
          <h2>{{ `${item.purchaserFirstName} ${item.purchaserLastName}` }}</h2>
          <h2>{{ item.purchaseBid }}</h2>
        </div>
      </div>
    </div>
    <div class="h-2 lg:h-4" />
  </div>
</template>

<style lang="scss" scoped>
.html-description {
  @apply text-sm lg:text-base leading-relaxed;

  :deep(ul), :deep(ol) {
    @apply pl-7 flex flex-col gap-1 not-last:mb-2.5 not-first:mt-2.5 lg:not-last:mb-3 lg:not-first:mt-3;
  }

  :deep(ul) {
    @apply pl-7 list-disc;
  }

  :deep(ol) {
    @apply pl-7 list-decimal;
  }

  :deep(strong) {
    @apply font-semibold;
  }

  :deep(h2), :deep(h1) {
    @apply text-2xl lg:text-3xl uppercase font-accent tracking-wider font-medium text-t-text not-last:mb-2.5 not-first:mt-5 lg:not-last:mb-4 lg:not-first:mt-8;
  }

  :deep(p) {
    @apply not-first:mt-1.5 lg:not-first:mt-2;
  }

  :deep(a) {
    @apply font-medium text-t-text border-b border-t-text/20 dark:border-t-text/20 transition-colors hover:border-t-text/50 dark:hover:border-t-text/50;
  }
}
</style>
